<template>
	<div>
		<h1 class="text-2xl font-semibold text-gray-900">Page ID: {{ id }}</h1>
	</div>
</template>

<script>
export default {
	data() {
		return {
			id: this.$route.params.id,
		}
	},
}
</script>
